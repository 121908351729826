/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Payment intent status
 * @export
 */
export const PaymentIntentStatus = {
  Created: 'CREATED',
  OrderInitiated: 'ORDER_INITIATED',
  LiquidationInitiated: 'LIQUIDATION_INITIATED',
  Completed: 'COMPLETED',
  Failed: 'FAILED',
} as const;
export type PaymentIntentStatus = (typeof PaymentIntentStatus)[keyof typeof PaymentIntentStatus];

export function instanceOfPaymentIntentStatus(value: any): boolean {
  for (const key in PaymentIntentStatus) {
    if (Object.prototype.hasOwnProperty.call(PaymentIntentStatus, key)) {
      if (PaymentIntentStatus[key as keyof typeof PaymentIntentStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function PaymentIntentStatusFromJSON(json: any): PaymentIntentStatus {
  return PaymentIntentStatusFromJSONTyped(json, false);
}

export function PaymentIntentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentIntentStatus {
  return json as PaymentIntentStatus;
}

export function PaymentIntentStatusToJSON(value?: PaymentIntentStatus | null): any {
  return value as any;
}
