/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoMoney } from './CryptoMoney';
import { CryptoMoneyFromJSON, CryptoMoneyFromJSONTyped, CryptoMoneyToJSON } from './CryptoMoney';
import type { FiatMoney } from './FiatMoney';
import { FiatMoneyFromJSON, FiatMoneyFromJSONTyped, FiatMoneyToJSON } from './FiatMoney';

/**
 *
 * @export
 * @interface PaymentIntentOrder
 */
export interface PaymentIntentOrder {
  /**
   *
   * @type {CryptoMoney}
   * @memberof PaymentIntentOrder
   */
  crypto: CryptoMoney;
  /**
   *
   * @type {FiatMoney}
   * @memberof PaymentIntentOrder
   */
  fiat: FiatMoney;
  /**
   * Crypto exchange rate
   * @type {string}
   * @memberof PaymentIntentOrder
   */
  rate: string;
}

/**
 * Check if a given object implements the PaymentIntentOrder interface.
 */
export function instanceOfPaymentIntentOrder(value: object): value is PaymentIntentOrder {
  if (!('crypto' in value) || value['crypto'] === undefined) return false;
  if (!('fiat' in value) || value['fiat'] === undefined) return false;
  if (!('rate' in value) || value['rate'] === undefined) return false;
  return true;
}

export function PaymentIntentOrderFromJSON(json: any): PaymentIntentOrder {
  return PaymentIntentOrderFromJSONTyped(json, false);
}

export function PaymentIntentOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentIntentOrder {
  if (json == null) {
    return json;
  }
  return {
    crypto: CryptoMoneyFromJSON(json['crypto']),
    fiat: FiatMoneyFromJSON(json['fiat']),
    rate: json['rate'],
  };
}

export function PaymentIntentOrderToJSON(value?: PaymentIntentOrder | null): any {
  if (value == null) {
    return value;
  }
  return {
    crypto: CryptoMoneyToJSON(value['crypto']),
    fiat: FiatMoneyToJSON(value['fiat']),
    rate: value['rate'],
  };
}
