export default {
  title: 'Pay with crypto',
  common: {
    continue: 'Continue',
    'unknown-error': 'Something Went Wrong',
    next: 'Next',
    'try-again': 'Please try again',
    'unknown-error-description': 'We apologize for any inconvenience, but an unexpected error occurred.',
    back: 'Back',
    verify: 'Verify',
    understood: 'Understood',
    success: 'Success',
    fail: 'Fail',
    'invalid-code': 'Invalid code',
    'years-short': 'y',
    'months-short': 'm',
    'days-short': 'd',
  },
  'not-found': {
    title: 'Page Not Found',
    subtitle: 'go back',
    back: 'Back',
  },
  header: {
    'header-title': 'Pay with crypto',
  },
  menu: {
    faq: 'FAQ',
    'contact-support': 'Contact support',
    'about-simplex': 'About Simplex',
    'privacy-policy': 'Privacy policy',
    'terms-of-use': 'Terms of use',
    languages: {
      en: 'English',
      es: 'Español',
      de: 'Deutsch',
    },
    footer: {
      title: '{tp}',
      links: '{termsOfUseLink} & {privacyPolicyLink}',
      'terms-of-use': 'Terms of Use',
      'privacy-policy': 'Privacy Policy',
      address: '{address}',
    },
  },
  'order-summary': {
    'you-are-paying': 'You’re paying:',
    'you-are-selling': 'You’re selling:',
    'format-amount': '{amount} {currency}',
    'you-get': 'You get:',
    'you-sell': 'You sell:',
    'fees-included': 'Fees included',
    'selling-to-lp': 'Via {liquidityProvider}',
    'sell-details-title': "You're selling:",
    'sell-details-fees': 'Fees included',
    'sell-details-fees-tooltip': 'Fees included tooltip',
    'sell-details-fees-tooltip-processing-fee': 'Processing fee',
    'sell-details-fees-tooltip-blockchain-fee': 'Blockchain fee',
    'sell-details-fees-tooltip-crypto-fee': 'Crypto fee',
    'sell-details-fees-tooltip-total-amount': 'Total amount',
    'sell-details-fees-tooltip-total-amount-including-fees': 'You will receive',
    'sell-details-fees-tooltip-simplex-fee': 'Simplex fee',
    'sell-details-fees-tooltip-crypto': 'Crypto',
    'sell-details-fees-tooltip-exchange-rate': 'Exchange rate',
    'sell-details-to': 'Selling to {liquidityProvider}',
    'rate-may-change': 'Rate may change',
    'sell-details-to-tooltip':
      '{liquidityProvider} is the crypto exchange provider for this transaction. Simplex is platform provider on which this exchange is made.',
    'sell-details-grid-format-total-amount-excluding-fees': '~ {amount} {currency}',
    'sell-details-grid-format-processing-fee': '– {amount} {currency}',
    'sell-details-grid-format-total-amount-including-fees': '~ {amount} {currency}',
    'sell-details-grid-format-rate-crypto': '1 {crypto} ≈',
    'sell-details-grid-format-rate-value': '{rate} {currency}',
  },
  offers: {
    title: 'Choose your crypto',
    rate: '~ {rate}',
    'pay-with-crypto': 'Pay with {cryptoCurrency}',
  },
  'send-crypto': {
    title: 'Send Your Crypto',
    description: 'Scan the QR code with your wallet or copy the details to complete your payment {timer}',
    'description-timer': '({timer} left)',
    'description-timer-timeout': 'refresh',
    amount: 'Amount To Send',
    explanation: '{icon} Supports {currency} on the {network} network',
    'amount-to-send-format': '{amount} {currency}',
    'wallet-address': 'Send To This Wallet Address',
    'wallet-info': 'This wallet supports [{cryptoNetwork}] on the [{cryptoNetworkName}] network',
    'bottom-info':
      'This screen will be updated Automatically as soon as you send your crypto and the transaction is registered on the blockchain',
    modal: {
      title: 'Before Sending Your Crypto',
      'button-label': 'Confirm',
      'first-line': 'You’re sending the correct {amount} including Blockchain fee or wallet fees (if needed).',
      'first-line-amount': 'Amount',
      'second-line': 'You’ve checked the {address} carefully',
      'second-line-address': 'Destination address',
      'third-line': 'You’re sending to the correct {network}',
      'third-line-network': 'Network',
      'fourth-line':
        'You’re about to send your Cryptocurrency to a deposit wallet address, before doing that please verify:',
    },
  },
  payment: {
    'you-sell': 'You sell',
    'you-get': 'You get',
    expired: {
      title: 'Your Payment Has Expired',
      'description-row-1': 'Any payments received after the expiration time will be refunded.',
      'description-row-2': 'If you have any questions, please contact our {supportTeam}',
      'description-row-3': 'Payment ID: {paymentIntentId}',
      'description-row-2-support-team': 'support team',
      continue: 'Start new payment',
    },
    processing: {
      title: 'Processing Your Payment',
      amount: 'Amount',
      'description-row-1': 'Payment detected on the blockchain.',
      'description-row-2': 'The merchant will be notified, and your order will be completed.',
      'description-row-3': 'You’ll receive an email confirmation once approved.',
      'footer-title': 'What’s next?',
      'footer-description': 'You’re payment is on the blockchain, waiting to receive it.',
    },
    success: {
      title: 'Your Payment Was Approved',
      description: 'We’ll email you further details (check your spam folder)',
      amount: 'Amount',
      continue: 'All Done',
    },
    failed: {
      title: 'Your Payment Was Unsuccessful',
      'description-row-1': 'Unfortunately, we were unable to process your payment.',
      'description-row-2': 'For more information, please get in touch with our {0}',
      support: 'support team',
      continue: 'Back To {tp}',
    },
    'too-low': {
      title: 'Your Payment Was Approved',
      status: 'Status',
      sending: 'SENDING PAYMENT',
      'description-row-1': "What's next?",
      'description-row-2': 'We’ll send you an email with more details',
      continue: 'Back To {tp}',
    },
    'too-high': {
      title: 'Your Payment Was Approved',
      status: 'Status',
      sending: 'SENDING PAYMENT',
      'description-row-1': "What's next?",
      'description-row-2': 'We’ll send you an email with more details',
      continue: 'Back To {tp}',
    },
  },
  refund: {
    init: {
      title: 'Your Payment Was Unsuccessful',
      'description-row-1': 'Unfortunately, we were unable to process your payment.',
      'description-row-2': 'For more information, please get in touch with our {0}',
      support: 'support team',
      continue: 'Back To {tp}',
    },
    processing: {
      title: 'Processing Your Payment',
      'description-row-1': "What's next?",
      'description-row-2': 'Your payment is on the blockchain, waiting to receive it.',
    },
    success: {
      title: 'Your Payment Was Approved',
      status: 'Status',
      sending: 'SENDING PAYMENT',
      'description-row-1': "What's next?",
      'description-row-2': 'We’ll send you an email with more details',
      continue: 'Back To {tp}',
    },
    failed: {
      title: 'Your Payment Was Unsuccessful',
      'description-row-1': 'Unfortunately, we were unable to process your payment.',
      'description-row-2': 'For more information, please get in touch with our {0}',
      support: 'support team',
      continue: 'Back To {tp}',
    },
  },
  'merchant-refund': {
    successful: {
      title: 'Refund Successful!',
      'refund-money-label': 'Refund',
      'description-row-1': "What's next?",
      'description-row-2': "We'll email you further details (check your spam folder)",
      continue: 'Continue',
    },
    processing: {
      title: 'Processing Your Refund',
      'refund-money-label': 'Refund',
      'description-row-1': "What's next?",
      'description-row-2': "We'll email you further details (check your spam folder)",
    },
  },
  'automatic-refund': {
    successful: {
      title: 'Refund Successful!',
      'refund-money-label': 'Refund',
      'description-row-1': "What's next?",
      'description-row-2': "We'll email you further details (check your spam folder)",
      continue: 'Continue',
    },
    processing: {
      title: 'Processing Your Refund',
      'refund-money-label': 'Refund',
      'description-row-1': "What's next?",
      'description-row-2': "We'll email you further details (check your spam folder)",
    },
  },
};
