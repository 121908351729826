/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoMoney } from './CryptoMoney';
import { CryptoMoneyFromJSON, CryptoMoneyFromJSONTyped, CryptoMoneyToJSON } from './CryptoMoney';
import type { FiatMoney } from './FiatMoney';
import { FiatMoneyFromJSON, FiatMoneyFromJSONTyped, FiatMoneyToJSON } from './FiatMoney';

/**
 *
 * @export
 * @interface PaymentIntentAutomaticRefundOrder
 */
export interface PaymentIntentAutomaticRefundOrder {
  /**
   *
   * @type {CryptoMoney}
   * @memberof PaymentIntentAutomaticRefundOrder
   */
  crypto: CryptoMoney;
  /**
   *
   * @type {FiatMoney}
   * @memberof PaymentIntentAutomaticRefundOrder
   */
  fiat: FiatMoney;
  /**
   *
   * @type {CryptoMoney}
   * @memberof PaymentIntentAutomaticRefundOrder
   */
  networkFee?: CryptoMoney;
}

/**
 * Check if a given object implements the PaymentIntentAutomaticRefundOrder interface.
 */
export function instanceOfPaymentIntentAutomaticRefundOrder(value: object): value is PaymentIntentAutomaticRefundOrder {
  if (!('crypto' in value) || value['crypto'] === undefined) return false;
  if (!('fiat' in value) || value['fiat'] === undefined) return false;
  return true;
}

export function PaymentIntentAutomaticRefundOrderFromJSON(json: any): PaymentIntentAutomaticRefundOrder {
  return PaymentIntentAutomaticRefundOrderFromJSONTyped(json, false);
}

export function PaymentIntentAutomaticRefundOrderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentAutomaticRefundOrder {
  if (json == null) {
    return json;
  }
  return {
    crypto: CryptoMoneyFromJSON(json['crypto']),
    fiat: FiatMoneyFromJSON(json['fiat']),
    networkFee: json['network_fee'] == null ? undefined : CryptoMoneyFromJSON(json['network_fee']),
  };
}

export function PaymentIntentAutomaticRefundOrderToJSON(value?: PaymentIntentAutomaticRefundOrder | null): any {
  if (value == null) {
    return value;
  }
  return {
    crypto: CryptoMoneyToJSON(value['crypto']),
    fiat: FiatMoneyToJSON(value['fiat']),
    network_fee: CryptoMoneyToJSON(value['networkFee']),
  };
}
