/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FailureUrl
 */
export interface FailureUrl {}

/**
 * Check if a given object implements the FailureUrl interface.
 */
export function instanceOfFailureUrl(value: object): value is FailureUrl {
  return true;
}

export function FailureUrlFromJSON(json: any): FailureUrl {
  return FailureUrlFromJSONTyped(json, false);
}

export function FailureUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailureUrl {
  return json;
}

export function FailureUrlToJSON(value?: FailureUrl | null): any {
  return value;
}
