/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Merchant
 */
export interface Merchant {}

/**
 * Check if a given object implements the Merchant interface.
 */
export function instanceOfMerchant(value: object): value is Merchant {
  return true;
}

export function MerchantFromJSON(json: any): Merchant {
  return MerchantFromJSONTyped(json, false);
}

export function MerchantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Merchant {
  return json;
}

export function MerchantToJSON(value?: Merchant | null): any {
  return value;
}
