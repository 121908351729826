/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoMoney } from './CryptoMoney';
import { CryptoMoneyFromJSON, CryptoMoneyFromJSONTyped, CryptoMoneyToJSON } from './CryptoMoney';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
} from './CryptoWalletAddressWithTag';

/**
 *
 * @export
 * @interface CryptoExecution
 */
export interface CryptoExecution {
  /**
   * Transaction id identifying exact transaction in blockchain
   * @type {string}
   * @memberof CryptoExecution
   */
  blockchainTransactionHash?: string;
  /**
   *
   * @type {CryptoMoney}
   * @memberof CryptoExecution
   */
  cryptoToExecute: CryptoMoney;
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof CryptoExecution
   */
  id: string;
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof CryptoExecution
   */
  recipientWallet: CryptoWalletAddressWithTag;
  /**
   * Opaque object identifier
   * @type {string}
   * @memberof CryptoExecution
   */
  merchantRefundId?: string;
  /**
   *
   * @type {string}
   * @memberof CryptoExecution
   */
  status: CryptoExecutionStatus;
}

/**
 * @export
 */
export const CryptoExecutionStatus = {
  Completed: 'COMPLETED',
  Created: 'CREATED',
  Failed: 'FAILED',
} as const;
export type CryptoExecutionStatus = (typeof CryptoExecutionStatus)[keyof typeof CryptoExecutionStatus];

/**
 * Check if a given object implements the CryptoExecution interface.
 */
export function instanceOfCryptoExecution(value: object): value is CryptoExecution {
  if (!('cryptoToExecute' in value) || value['cryptoToExecute'] === undefined) return false;
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('recipientWallet' in value) || value['recipientWallet'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function CryptoExecutionFromJSON(json: any): CryptoExecution {
  return CryptoExecutionFromJSONTyped(json, false);
}

export function CryptoExecutionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoExecution {
  if (json == null) {
    return json;
  }
  return {
    blockchainTransactionHash:
      json['blockchain_transaction_hash'] == null ? undefined : json['blockchain_transaction_hash'],
    cryptoToExecute: CryptoMoneyFromJSON(json['crypto_to_execute']),
    id: json['id'],
    recipientWallet: CryptoWalletAddressWithTagFromJSON(json['recipient_wallet']),
    merchantRefundId: json['merchant_refund_id'] == null ? undefined : json['merchant_refund_id'],
    status: json['status'],
  };
}

export function CryptoExecutionToJSON(value?: CryptoExecution | null): any {
  if (value == null) {
    return value;
  }
  return {
    blockchain_transaction_hash: value['blockchainTransactionHash'],
    crypto_to_execute: CryptoMoneyToJSON(value['cryptoToExecute']),
    id: value['id'],
    recipient_wallet: CryptoWalletAddressWithTagToJSON(value['recipientWallet']),
    merchant_refund_id: value['merchantRefundId'],
    status: value['status'],
  };
}
