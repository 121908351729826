/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SuccessUrl
 */
export interface SuccessUrl {}

/**
 * Check if a given object implements the SuccessUrl interface.
 */
export function instanceOfSuccessUrl(value: object): value is SuccessUrl {
  return true;
}

export function SuccessUrlFromJSON(json: any): SuccessUrl {
  return SuccessUrlFromJSONTyped(json, false);
}

export function SuccessUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuccessUrl {
  return json;
}

export function SuccessUrlToJSON(value?: SuccessUrl | null): any {
  return value;
}
