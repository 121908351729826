/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoMoney } from './CryptoMoney';
import { CryptoMoneyFromJSON, CryptoMoneyFromJSONTyped, CryptoMoneyToJSON } from './CryptoMoney';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
} from './CryptoWalletAddressWithTag';

/**
 *
 * @export
 * @interface CryptoTransferCreated
 */
export interface CryptoTransferCreated {
  /**
   * Transaction id identifying exact transaction in blockchain
   * @type {string}
   * @memberof CryptoTransferCreated
   */
  blockchainTransactionHash?: string;
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof CryptoTransferCreated
   */
  destination: CryptoWalletAddressWithTag;
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof CryptoTransferCreated
   */
  id: string;
  /**
   *
   * @type {CryptoMoney}
   * @memberof CryptoTransferCreated
   */
  receivedCrypto?: CryptoMoney;
  /**
   *
   * @type {string}
   * @memberof CryptoTransferCreated
   */
  status: CryptoTransferCreatedStatus;
}

/**
 * @export
 */
export const CryptoTransferCreatedStatus = {
  Completed: 'COMPLETED',
  Confirming: 'CONFIRMING',
  Created: 'CREATED',
  Expired: 'EXPIRED',
} as const;
export type CryptoTransferCreatedStatus =
  (typeof CryptoTransferCreatedStatus)[keyof typeof CryptoTransferCreatedStatus];

/**
 * Check if a given object implements the CryptoTransferCreated interface.
 */
export function instanceOfCryptoTransferCreated(value: object): value is CryptoTransferCreated {
  if (!('destination' in value) || value['destination'] === undefined) return false;
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function CryptoTransferCreatedFromJSON(json: any): CryptoTransferCreated {
  return CryptoTransferCreatedFromJSONTyped(json, false);
}

export function CryptoTransferCreatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoTransferCreated {
  if (json == null) {
    return json;
  }
  return {
    blockchainTransactionHash:
      json['blockchain_transaction_hash'] == null ? undefined : json['blockchain_transaction_hash'],
    destination: CryptoWalletAddressWithTagFromJSON(json['destination']),
    id: json['id'],
    receivedCrypto: json['received_crypto'] == null ? undefined : CryptoMoneyFromJSON(json['received_crypto']),
    status: json['status'],
  };
}

export function CryptoTransferCreatedToJSON(value?: CryptoTransferCreated | null): any {
  if (value == null) {
    return value;
  }
  return {
    blockchain_transaction_hash: value['blockchainTransactionHash'],
    destination: CryptoWalletAddressWithTagToJSON(value['destination']),
    id: value['id'],
    received_crypto: CryptoMoneyToJSON(value['receivedCrypto']),
    status: value['status'],
  };
}
