/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const PaymentIntentMerchantRefundStatus = {
  Completed: 'COMPLETED',
  Created: 'CREATED',
  QuoteCreated: 'QUOTE_CREATED',
  DestinationCollected: 'DESTINATION_COLLECTED',
  Failed: 'FAILED',
} as const;
export type PaymentIntentMerchantRefundStatus =
  (typeof PaymentIntentMerchantRefundStatus)[keyof typeof PaymentIntentMerchantRefundStatus];

export function instanceOfPaymentIntentMerchantRefundStatus(value: any): boolean {
  for (const key in PaymentIntentMerchantRefundStatus) {
    if (Object.prototype.hasOwnProperty.call(PaymentIntentMerchantRefundStatus, key)) {
      if (PaymentIntentMerchantRefundStatus[key as keyof typeof PaymentIntentMerchantRefundStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function PaymentIntentMerchantRefundStatusFromJSON(json: any): PaymentIntentMerchantRefundStatus {
  return PaymentIntentMerchantRefundStatusFromJSONTyped(json, false);
}

export function PaymentIntentMerchantRefundStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentMerchantRefundStatus {
  return json as PaymentIntentMerchantRefundStatus;
}

export function PaymentIntentMerchantRefundStatusToJSON(value?: PaymentIntentMerchantRefundStatus | null): any {
  return value as any;
}
