import { type RouteRecord } from './router';

export type RouteName =
  | 'home'
  | 'offers'
  | 'send-crypto-consent'
  | 'send-crypto'
  | 'payment-expired'
  | 'payment-processing'
  | 'payment-success'
  | 'payment-failed'
  | 'payment-too-low'
  | 'payment-too-high'
  | 'refund-processing'
  | 'refund-init'
  | 'refund-processing'
  | 'refund-failed'
  | 'refund-success'
  | 'not-found'
  | 'merchant-refund-successful'
  | 'automatic-refund-successful'
  | 'merchant-refund-processing'
  | 'automatic-refund-processing';

export const routes: Array<RouteRecord> = [
  {
    path: '/payment-intent/:id([\\da-f]{8}-[\\da-f]{4}-[\\da-f]{4}-[\\da-f]{4}-[\\da-f]{12})',
    name: 'home',
    component: () => import('../../home/PageHome.vue'),
    props: true,
    children: [
      {
        path: 'offers',
        name: 'offers',
        component: () => import('../../offers/PageOffers.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'send-crypto-consent',
        name: 'send-crypto-consent',
        component: () => import('../../send-crypto-consent/PageSendCryptoConsent.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'send-crypto',
        name: 'send-crypto',
        component: () => import('../../send-crypto/PageSendCrypto.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-expired',
        name: 'payment-expired',
        component: () => import('../../payment/expired/PagePaymentExpired.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-success',
        name: 'payment-success',
        component: () => import('../../payment/success/PagePaymentSuccess.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-processing',
        name: 'payment-processing',
        component: () => import('../../payment/processing/PagePaymentProcessing.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-too-low',
        name: 'payment-too-low',
        component: () => import('../../payment/too-low/PagePaymentTooLow.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-too-high',
        name: 'payment-too-high',
        component: () => import('../../payment/too-high/PagePaymentTooHigh.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-failed',
        name: 'payment-failed',
        component: () => import('../../payment/failed/PagePaymentFailed.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'refund-init',
        name: 'refund-init',
        component: () => import('../../refund/init/PageRefundInit.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'refund-processing',
        name: 'refund-processing',
        component: () => import('../../refund/processing/PageRefundProcessing.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'refund-failed',
        name: 'refund-failed',
        component: () => import('../../refund/failed/PageRefundFailed.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'refund-success',
        name: 'refund-success',
        component: () => import('../../refund/success/PageRefundSuccess.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'merchant-refund-successful',
        name: 'merchant-refund-successful',
        component: () => import('../../merchant-refund/PageMerchantRefundSuccessful.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'automatic-refund-successful',
        name: 'automatic-refund-successful',
        component: () => import('../../automatic-refund/PageAutomaticRefundSuccessful.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'merchant-refund-processing',
        name: 'merchant-refund-processing',
        component: () => import('../../merchant-refund/PageMerchantRefundProcessing.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'automatic-refund-processing',
        name: 'automatic-refund-processing',
        component: () => import('../../automatic-refund/PageAutomaticRefundProcessing.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../../not-found/PageNotFound.vue'),
  },
];
