/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const CryptoTransferStatus = {
  Completed: 'COMPLETED',
  Confirming: 'CONFIRMING',
  Created: 'CREATED',
  Expired: 'EXPIRED',
  ReceivedExpiredCrypto: 'RECEIVED_EXPIRED_CRYPTO',
} as const;
export type CryptoTransferStatus = (typeof CryptoTransferStatus)[keyof typeof CryptoTransferStatus];

export function instanceOfCryptoTransferStatus(value: any): boolean {
  for (const key in CryptoTransferStatus) {
    if (Object.prototype.hasOwnProperty.call(CryptoTransferStatus, key)) {
      if (CryptoTransferStatus[key as keyof typeof CryptoTransferStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function CryptoTransferStatusFromJSON(json: any): CryptoTransferStatus {
  return CryptoTransferStatusFromJSONTyped(json, false);
}

export function CryptoTransferStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoTransferStatus {
  return json as CryptoTransferStatus;
}

export function CryptoTransferStatusToJSON(value?: CryptoTransferStatus | null): any {
  return value as any;
}
