/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CryptoTransferCreate
 */
export interface CryptoTransferCreate {
  /**
   * Opaque object identifier
   * @type {string}
   * @memberof CryptoTransferCreate
   */
  paymentIntentId: string;
}

/**
 * Check if a given object implements the CryptoTransferCreate interface.
 */
export function instanceOfCryptoTransferCreate(value: object): value is CryptoTransferCreate {
  if (!('paymentIntentId' in value) || value['paymentIntentId'] === undefined) return false;
  return true;
}

export function CryptoTransferCreateFromJSON(json: any): CryptoTransferCreate {
  return CryptoTransferCreateFromJSONTyped(json, false);
}

export function CryptoTransferCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoTransferCreate {
  if (json == null) {
    return json;
  }
  return {
    paymentIntentId: json['payment_intent_id'],
  };
}

export function CryptoTransferCreateToJSON(value?: CryptoTransferCreate | null): any {
  if (value == null) {
    return value;
  }
  return {
    payment_intent_id: value['paymentIntentId'],
  };
}
