/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
} from './CryptoWalletAddressWithTag';

/**
 *
 * @export
 * @interface PaymentIntentMerchantRefundPatch
 */
export interface PaymentIntentMerchantRefundPatch {
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof PaymentIntentMerchantRefundPatch
   */
  refundDestination: CryptoWalletAddressWithTag;
}

/**
 * Check if a given object implements the PaymentIntentMerchantRefundPatch interface.
 */
export function instanceOfPaymentIntentMerchantRefundPatch(value: object): value is PaymentIntentMerchantRefundPatch {
  if (!('refundDestination' in value) || value['refundDestination'] === undefined) return false;
  return true;
}

export function PaymentIntentMerchantRefundPatchFromJSON(json: any): PaymentIntentMerchantRefundPatch {
  return PaymentIntentMerchantRefundPatchFromJSONTyped(json, false);
}

export function PaymentIntentMerchantRefundPatchFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentMerchantRefundPatch {
  if (json == null) {
    return json;
  }
  return {
    refundDestination: CryptoWalletAddressWithTagFromJSON(json['refund_destination']),
  };
}

export function PaymentIntentMerchantRefundPatchToJSON(value?: PaymentIntentMerchantRefundPatch | null): any {
  if (value == null) {
    return value;
  }
  return {
    refund_destination: CryptoWalletAddressWithTagToJSON(value['refundDestination']),
  };
}
